.form {
  display: flex;
  flex-flow: column;
}

.passwordHelpText {
  .requirement {
    align-items: center;
    display: flex;
    gap: 0.375rem;
    padding: 0.25rem 0;

    &.requirementIndeterminate {
      :global(svg) {
        color: var(--gray-400);
      }
    }
    &.requirementFailed {
      color: var(--error-500);
    }
    &.requirementPassed {
      color: var(--secondary-700);
    }

    :global(svg) {
      height: 0.75rem;
      flex: 0 0 auto;
      height: 0.75rem;
    }
  }
}

.errorMessage {
  color: var(--error-500) !important;
}
