@import '../../styles/mixins';

.inputSearch {
  .input {
    padding-right: 3.5rem;
  }

  .variantWhite {
    &:global(.chakra-input) {
      background-color: white !important;
      &:hover {
        background-color: white !important;
      }
    }
  }

  .variantLight {
    &:global(.chakra-input) {
      background-color: var(--gray-50) !important;
      border-color: var(--gray-50) !important;
      &:hover {
        background-color: var(--gray-50) !important;
        border-color: var(--gray-50) !important;
      }
    }
  }
  .variantMedium {
    &:global(.chakra-input) {
      background-color: var(--gray-100) !important;
      border-color: var(--gray-100) !important;
      &:hover {
        background-color: var(--gray-100) !important;
        border-color: var(--gray-100) !important;
      }
    }
  }

  .leftControls,
  .rightControls {
    top: 0.1875rem;
  }
  .leftControls {
    // Tweak the size of this particular icon: the inner offset makes it look a little too large with the standard
    // icon container's 1.5rem x 1.5rem sizing.
    :global(svg) {
      height: 1rem !important;
      width: 1rem !important;
    }
  }
  .rightControls {
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
    width: auto;
  }

  .iconContainer {
    color: var(--gray-600);

    [disabled],
    &.iconContainerDisabled {
      color: var(--gray-200);
    }

    :global(svg) {
      height: 1.5rem;
      width: 1.5rem;
    }
  }
}
