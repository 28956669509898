$listBorderWidth: 1px;

.outerListWrapper {
  .selectAll {
    // we need to add 1px here to account for the border width
    padding: 0 calc(1rem + $listBorderWidth);

    &:hover {
      cursor: pointer;
    }
  }

  .searchInput {
    margin-bottom: 0.625rem;
  }

  .innerListWrapper {
    background-color: var(--white);
    border: $listBorderWidth solid var(--gray-200);
    border-radius: 0.5rem;

    .listOptions {
      max-height: 17.5rem;
      overflow-y: auto;

      .listOption {
        padding: 0.75rem 1rem;

        &.clickable {
          &:hover {
            cursor: pointer;
          }
        }
      }

      .buttonGroup {
        position: sticky;
        bottom: 0;
      }
    }
  }
}
