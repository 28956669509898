@import '../../styles/mixins';

.form {
  display: flex;
  flex-flow: column;
}

.forgotPasswordLink {
  // If we used padding here, the vertical spacing would be interactable.
  margin: 1rem 0 1.5rem;
  max-width: fit-content;
}

.divider {
  display: flex;
  align-items: center;
  padding: 0 0 2rem;

  :global(hr) {
    flex: 1;
  }
  :global(span) {
    @include text-heading-5;
    color: var(--gray-600);
    text-transform: uppercase;
    padding: 0 1rem;
  }
}

.partnerLogins {
  display: flex;
  flex-flow: column;

  // Flexbox gap doesn't have great support yet, so use margin instead.
  :global(.chakra-button) {
    margin-bottom: 1rem;
    margin-top: 0 !important;
  }
  :global(img) {
    height: 1.5rem;
    margin-right: 1rem;
    width: 1.5rem;
  }

  @include tablet {
    display: grid;
    gap: 1rem;
    grid: auto-flow / repeat(2, 1fr);
  }
}

.passwordHelpText {
  .requirement {
    align-items: center;
    display: flex;
    gap: 0.375rem;
    padding: 0.25rem 0;

    &.requirementIndeterminate {
      :global(svg) {
        color: var(--gray-400);
      }
    }
    &.requirementFailed {
      color: var(--error-500);
    }
    &.requirementPassed {
      color: var(--secondary-700);
    }

    :global(svg) {
      height: 0.75rem;
      flex: 0 0 auto;
      height: 0.75rem;
    }
  }
}

.errorMessage {
  color: var(--error-500) !important;
}
