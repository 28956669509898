@import '../styles/mixins';

.form {
  :global(h1) {
    @include text-heading-3;
    font-size: 2.5rem;
    letter-spacing: -0.01em;
    line-height: 1.2;
    padding-bottom: 1.5rem;
  }
  :global(h2) {
    @include text-heading-5;
    padding: 1rem 0 1.25rem;
  }
  :global(p) {
    @include text-body-medium;
    line-height: 1.55;
    padding: 0 0 1rem;
  }
  :global(.chakra-button) {
    height: auto;
    margin-top: 2rem;
  }
  :global(.chakra-form-control) {
    padding: 0 0 1.5rem;
  }
}
