@import '../../styles/mixins';

.formControlGrid {
  display: grid;
  gap: 1rem;
  grid: auto-flow min-content / 1fr;
  padding: 1.5rem 0 1rem;

  @include tablet {
    grid-template-columns: repeat(2, 1fr);
  }
}
