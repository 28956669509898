@import '../../styles/mixins';

.tableWrapper {
  margin: 1rem 0 0;
  flex: 1;
  overflow-y: hidden;
  display: flex;

  thead,
  tbody {
    tr {
      th {
        @include phone {
          font-size: 0.67rem; // 8px
        }
        @include phone-landscape {
          font-size: 0.7rem; // 9px
        }
        @include tablet {
          font-size: 0.71rem; // 10px
        }
        @include laptop {
          font-size: 0.75rem; // 12px
        }
      }
      th,
      td {
        &:nth-child(2) {
          @include phone {
            width: 8.5rem;
            white-space: pre-wrap;
          }
          @include phone-landscape {
            width: 10rem;
          }
          @include tablet {
            width: 12rem;
          }
          @include laptop {
            width: 14rem;
          }
        }
        &:nth-child(3) {
          @include phone {
            width: 8.5rem;
            white-space: pre-wrap;
          }
          @include phone-landscape {
            width: 10rem;
          }
          @include tablet {
            width: 12rem;
          }
          @include laptop {
            width: 14rem;
            white-space: unset;
          }
        }
        &:nth-child(5) {
          @include phone {
            width: 8.5rem;
            white-space: pre-wrap;
          }
          @include phone-landscape {
            width: 10rem;
          }
          @include tablet {
            width: 12rem;
          }
          @include laptop {
            width: 14rem;
            white-space: unset;
          }
        }
      }
    }
  }
}

.projectActions {
  align-items: stretch;
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  justify-content: space-evenly;
  width: 100%;

  /**
   * Since all links are very strongly styled off a `#root a` selector and these are lowEmphasis buttons with very high
   * specificity (not using a Chakra theme), we need a lot of !important here. This also isn't a simple candidate for
   * adding a new variant because it's a <Button> rendered with the `as` prop.
   * TODO: figure out how to handle this. Maybe a variant based on link is best?
   */
  :global(a) {
    border-radius: var(--rounding-large-button) !important;
    color: var(--gray-600) !important;
    height: var(--chakra-sizes-8); // 2rem
    margin: 0.125rem 0 !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    :global(svg) {
      height: 1.25rem;
      width: 1.25rem;
    }
    &:hover {
      color: var(--gray-900) !important;
    }
    &:not(:last-child) {
      border-right-width: 0.0625rem;
    }
  }

  @include tablet-landscape {
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;

    :global(a) {
      border-radius: initial !important;
      margin: 0 !important;
      &:first-child {
        border-bottom-left-radius: var(--rounding-large-button) !important;
        border-top-left-radius: var(--rounding-large-button) !important;
      }
      &:last-child {
        border-bottom-right-radius: var(--rounding-large-button) !important;
        border-top-right-radius: var(--rounding-large-button) !important;
      }
      &:not(:last-child) {
        border-right-width: 0;
      }
    }
  }
}

.projectMenuIcon {
  color: var(--gray-400);
  width: 1.25rem;
  height: 1.25rem;
}

.stateSelect {
  &:global(.chakra-select) {
    background-color: inherit !important;
    font-size: 1rem !important;
  }
}
