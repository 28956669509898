@import '../styles/mixins';

.attachmentGrid {
  display: grid;
  gap: 1rem;
  grid: auto-flow / 1fr;
  padding: 0 0 1rem;
}

@include tablet {
  .attachmentGrid {
    grid: auto-flow / repeat(2, 1fr);
  }
}

.attachment {
  &:focus {
    border-color: white;
    border-radius: var(--rounding-control);
    outline: 1px solid var(--primary-500);
    outline-offset: 0;
  }
  .attachmentName {
    cursor: pointer;
    overflow: hidden;
    padding: 0 3rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    z-index: var(--z-index-form-control-base);
  }
  .attachmentControls,
  .attachmentPreview {
    height: 100%;
    z-index: var(--z-index-form-control-add-on);
  }
  .attachmentControls:focus {
    border-color: white;
    border-radius: var(--rounding-control);
    outline: 1px solid var(--primary-500);
    outline-offset: 0;
  }
  .attachmentPreview {
    :global(img) {
      border-bottom-left-radius: var(--rounding-control);
      border-top-left-radius: var(--rounding-control);
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }
}
