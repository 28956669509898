// NOTE: this file to be split into others, will import others for fonts, etc.
// When redesign is complete, this and the new root folder should be renamed.

@import '~react-datepicker/dist/react-datepicker.css';

@import './styles/fonts';
@import './styles/mixins';

:root {
  --white: #fff;
  --gray-50: #fafafa;
  --gray-100: #efefef;
  --gray-200: #c9cfcb;
  --gray-200-rgb: 201, 207, 203; // storing gray-200 as an rgb tuple so that we can modify its opacity when needed
  --gray-300: #aeb7b2;
  --gray-400: #9ea5a1;
  --gray-500: #8c928e;
  --gray-600: #6c716e;
  --gray-700: #525755;
  --gray-800: #2a2f2c;
  --gray-900: #181b19;
  --primary-disabled: #e9dafa; // todo: use opacity instead
  --primary-100: #e8e4ff;
  --primary-200: #b4aaf3;
  --primary-300: #936ef7;
  --primary-400: #7142f1;
  --primary-500: #936ef7;
  --primary-600: #500bc1;
  --primary-700: #330790;
  --primary-800: #21055d;
  --primary-900: #0f0330;
  --secondary-100: #f1fce5;
  --secondary-200: #bcf4b3;
  --secondary-300: #aaf297;
  --secondary-400: #acee8d;
  --secondary-500: #7fcd64;
  --secondary-600: #41a902;
  --secondary-700: #388001;
  --secondary-800: #1e4f01;
  --secondary-900: #093300;
  --data-grid-header-row-height: 3rem;
  --data-grid-row-height: 6rem;
  --data-grid-ptd-header-row-height: 3rem;
  --data-grid-ptd-row-height: 4rem;
  --error-100: #ffdede;
  --error-200: #ec7643;
  --error-500: #cb5e3c;
  --warning-500: #fae9ae;
  --gutter-full-screen-page-horizontal: 3rem;
  --gutter-full-screen-page-vertical: 4rem;
  --gutter-page-horizontal: 1rem;
  --gutter-page-vertical: 1rem;
  --gutter-360-control-horizontal: 1rem;
  --gutter-360-control-vertical: 1rem;
  --gutter-popover: 1.5rem;
  --height-form-control: 2.875rem;
  --rounding-card: 0.5rem;
  --rounding-card-large: 3.75rem;
  --rounding-chip: 0.125rem;
  --rounding-chip-large: 1.5rem;
  --rounding-control: 0.375rem;
  --rounding-large-button: 2.875rem;
  --rounding-progress-bar: 0.5rem;
  --rounding-sidebar-button: 0.25rem;
  --rounding-small-button: 1.25rem;
  --rounding-view-360-button: 0.25rem;
  --transition-duration-coach: 2500ms;
  --transition-duration-popover: 60ms;
  --width-drawer: 22rem;
  --width-drawer-close-button: 2rem;
  --width-sidebar: 3.75rem;
  --width-sidebar-bottom-icon: 2.25rem;
  --width-sidebar-nav-icon: 1.75rem;
  --z-index-form-control-base: 3;
  --z-index-form-control-add-on: 4;
  --z-index-form-control-listbox: 5;
  --z-index-view360-controls: 7;
  --z-index-view360-expanded-minimap: 8;
  --z-index-sidebar: 10;
}

@include tablet {
  :root {
    --gutter-full-screen-page-horizontal: 4.25rem;
    --gutter-full-screen-page-vertical: 5.125rem;
    --gutter-page-horizontal: 2rem;
    --gutter-page-vertical: 1.5rem;
    --width-sidebar: 4.25rem;
  }
}

* {
  @include font-neue-montreal;
}

// One of the biggest benefits of using rem units is taking advantage of font scaling.
// The rebranding designs were made for a viewport of width 1680px. For a first cut towards responsiveness, we can
// scale the base font size according to viewport width using breakpoints.
html {
  background-color: var(--gray-100);
  font-size: 10px;

  @include phone {
    font-size: 12px;
  }
  @include phone-landscape {
    font-size: 13px;
  }
  @include tablet {
    font-size: 14px;
  }
  @include tablet-landscape {
    font-size: 15px;
  }
  @include laptop {
    font-size: 16px;
  }
  // @include desktop {
  //   font-size: 16px;
  // }
}

body {
  background-color: var(--gray-100) !important;
  overflow-x: hidden;
  overflow-y: auto;
}

// To gain a higher specificity (to override Chakra), wrap the following rulesets in #root. Modals and popovers render
// within React portals, so cover that as well.
#root,
.chakra-portal {
  a {
    color: var(--primary-500);

    &:hover {
      color: var(--primary-600);
    }
  }

  .chakra-checkbox__control {
    border-color: var(--primary-400);

    &[aria-checked='true'],
    &[data-checked] {
      background: var(--primary-400);
      border-color: var(--primary-400);
    }
  }
  .chakra-checkbox__label {
    color: var(--gray-800);
  }
  .chakra-form__label {
    @include text-body-small;
  }
  .chakra-form__error-message {
    --form-error-color: var(--error-200);
  }
  .chakra-input,
  .chakra-select,
  .chakra-textarea,
  .chakra-numberinput__field {
    @include text-body-detail;
    background-color: white;
    border: 1px solid var(--gray-200);
    border-radius: var(--rounding-control);

    &::placeholder,
    &[data-defaultselected='true'] {
      color: var(--gray-400);
      opacity: 1; // override user agent setting in firefox
    }
    &[aria-invalid='true'],
    &[data-invalid] {
      border-color: var(--error-200);
      box-shadow: 0 0 0 1px var(--error-200);
    }
    &:focus {
      background-color: white;
      border-color: white;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.04), 4px 2px 8px rgba(0, 0, 0, 0.06);
      outline: 1px solid var(--primary-500);
      outline-offset: 0;
    }
    &:hover:not([aria-invalid='true'], [data-invalid]) {
      background-color: white;
      border-color: var(--gray-200);
    }
  }
  .chakra-input,
  .chakra-select,
  .chakra-numberinput__field {
    min-height: var(--height-form-control);
  }
  .chakra-switch__track {
    --switch-bg: var(--primary-100);

    &[data-checked] {
      --switch-bg: var(--primary-300);
    }
  }

  .chakra-modal__body {
    padding: 0.25rem 0.25rem 0;
  }
  .chakra-modal__close-btn {
    color: var(--gray-800);
    right: 1.5rem;
    top: 1.5rem;

    &:hover {
      --close-button-bg: var(--gray-100);
    }
  }
  .chakra-modal__content {
    padding: 1rem 1.5rem 1.5rem;
  }
  .chakra-modal__header {
    @include text-heading-5;
    padding: 0.5rem 0 1rem 0.25rem;
  }
  .chakra-modal__footer {
    padding: 0.75rem 0 0;
  }

  .chakra-popover__close-btn {
    color: var(--gray-600);
    right: 1rem;
    top: 1rem;
  }
  .chakra-popover__header {
    @include text-body-medium-bold;
    border-bottom-color: transparent;
    color: var(--gray-600);
    padding: 1rem;
  }

  // Third-party
  .chakra-react-select__control {
    @include text-body-detail;
    background-color: white;
    border: 0.0625rem solid var(--gray-200);
    border-radius: var(--rounding-control);
    min-height: var(--height-form-control);

    &[aria-invalid='true'],
    &[data-invalid] {
      border-color: var(--error-200);
      box-shadow: 0 0 0 0.0625rem var(--error-200);
    }
    &:focus-visible,
    &[data-focus-visible] {
      background-color: white;
      border-color: white;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.04), 4px 2px 8px rgba(0, 0, 0, 0.06);
      outline: 0.0625rem solid var(--primary-500);
      outline-offset: 0;
    }
  }
  .chakra-react-select__control--is-focused {
    border: 0.0625rem solid var(--gray-200);
    outline: 0.0625rem solid var(--primary-500);
    outline-offset: 0;
  }
  .chakra-react-select__clear-indicator {
    color: var(--gray-600);
  }
  .chakra-react-select__dropdown-indicator {
    color: var(--gray-600);
    svg {
      height: 1.25rem;
      width: 1.25rem;
    }
  }
  .chakra-react-select__menu {
    --badge-bg: var(--primary-100);
    --badge-color: var(--primary-400);
    // Previously, augmented inputs (e.g. search, add attachment) would have left or right controls with a z-index
    // higher than the multi-select listbox. Alleviate this by bumping up the listbox z-index.
    z-index: var(--z-index-form-control-listbox);
  }
  .chakra-react-select__multi-value {
    --badge-bg: var(--primary-100);
    --badge-color: var(--primary-400);
  }
  .chakra-react-select__placeholder {
    color: var(--gray-600);
  }
}
