.sliderContainer {
  align-items: center;
  background-color: var(--gray-800);
  border-radius: var(--rounding-view-360-button);
  color: white;
  display: flex;
  flex-flow: column nowrap;
  position: absolute;
  right: 3rem;
  top: 0;
  width: 2.5rem;

  > :global(svg) {
    height: 1.5rem;
    width: 1.5rem;
    margin: 0.5rem 0;
  }
  :global(.chakra-slider) {
    // Add 0.0625rem / 1px of space to the top of the slider to account for the space between the brightness and "create
    // screenshot" buttons. We also add a bit of margin to account for the space taken up by the slider when it reaches
    // the min or max value.
    margin: calc(0.0625rem + 0.75rem) 0 0.75rem;
    // Align the slider aligns with the top of the "adjust brightness" button. The bottom aligns with the bottom of the
    // "zoom out" button. So cover 3 buttons, 2 spaces, and 2 button group gaps. If we add or remove buttons, the
    // following calculation has to be readjusted.
    // Each button is 2.5rem tall (40px in the design), has a 0.0625rem (1px) space between the next button, and a
    // 0.25rem (4px) gap between button groups. Finally, subtract some space on the top and bottom for the slider when
    // it reaches the min or max value.
    min-height: calc(3 * 2.5rem + 3 * 0.0625rem + 2 * 0.25rem - 0.75rem * 2);
  }
  :global(.chakra-slider__thumb) {
    background-color: var(--primary-500);
    --slider-thumb-size: 0.875rem;
  }
  :global(.chakra-slider__track) {
    --slider-bg: var(--gray-700);
    --slider-track-size: 0.125rem;
  }
  :global(.chakra-slider__filled-track) {
    --slider-bg: var(--primary-500);
    --slider-bg: linear-gradient(180deg, white 0%, var(--primary-500) 60%);
  }
}
