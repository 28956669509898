.datePicker {
  &:global(.react-datepicker) {
    font-family: inherit;
    font-size: inherit;
    color: inherit;
    border: 0;
    display: block;

    :global(.react-datepicker__month-container) {
      float: none;
    }
    :global(.react-datepicker__header) {
      background-color: inherit;
      border-bottom: 0;
      padding: 1rem 0.5rem 0;
    }
    :global(.react-datepicker__day-names) {
      margin: 0;
    }
    :global(.react-datepicker__month) {
      border-bottom: 0;
      padding: 0.5rem;
      margin: 0;
    }
    :global(.react-datepicker__day-name),
    :global(.react-datepicker__day),
    :global(.react-datepicker__time-name) {
      height: 2.25rem;
      margin: 0.03125rem;
      line-height: 2.25rem;
      width: 2.25rem;
    }
    :global(.react-datepicker__day) {
      border: 0.0625rem solid transparent;
      border-radius: 100%;
      color: var(--primary-400);

      &:hover:global(
          :not(.react-datepicker__day--disabled, .react-datepicker__day--excluded, .react-datepicker__day--selected)
        ) {
        background-color: var(--primary-100);
        border-color: var(--primary-100);
        color: var(--primary-400);
        outline: 0;
      }
      &:global(.react-datepicker__day--disabled),
      :global(.react-datepicker__day--excluded) {
        color: var(--gray-300);
      }
      &:global(.react-datepicker__day--keyboard-selected) {
        background-color: var(--primary-100);
        border-color: var(--primary-100);
        color: var(--primary-400);
        outline: 0;

        &:global(.react-datepicker__day--disabled) {
          background-color: inherit;
          border-color: transparent;
          color: var(--gray-300);
        }
      }
      &:global(.react-datepicker__day--selected) {
        background-color: var(--primary-400);
        border-radius: 100%;
        color: white;
      }
    }
  }
}

.customHeader {
  display: grid;
  gap: 0.5rem;
  grid: auto-flow / repeat(2, 1fr);
  padding: 0 0 0.5rem;
}
