@import '../../../styles/mixins';

.inviteUserStepTwo {
  .helperTextSection {
    @include text-body-detail;
    margin-bottom: 0.625rem;

    .helpIcon {
      color: var(--gray-400);
      width: 1.125rem;
      height: 1.125rem;
    }
  }
}
