// Only provide woff and woff2 since we don't need to support IE.
// See https://caniuse.com/woff

@font-face {
  font-family: 'APK Protocol';
  src: url('../../public/fonts/APK-Protocol-Regular.woff2') format('woff2'),
    url('../../public/fonts/APK-Protocol-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'APK Protocol';
  src: url('../../public/fonts/APK-Protocol-Bold.woff2') format('woff2'),
    url('../../public/fonts/APK-Protocol-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Neue Montreal';
  src: url('../../public/fonts/PPNeueMontreal-Regular.woff2') format('woff2'),
    url('../../public/fonts/PPNeueMontreal-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Neue Montreal';
  src: url('../../public/fonts/PPNeueMontreal-Italic.woff2') format('woff2'),
    url('../../public/fonts/PPNeueMontreal-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Neue Montreal';
  src: url('../../public/fonts/PPNeueMontreal-Bold.woff2') format('woff2'),
    url('../../public/fonts/PPNeueMontreal-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Neue Montreal';
  src: url('../../public/fonts/PPNeueMontreal-BoldItalic.woff2') format('woff2'),
    url('../../public/fonts/PPNeueMontreal-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}
