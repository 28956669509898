@import '../../styles/mixins';

.sidebar {
  background-color: var(--gray-800);
  display: flex;
  flex-flow: column nowrap;
  height: 100vh;
  height: 100dvh; // On mobile, span full height but don't cause overflow (see "dynamic viewport units")
  justify-content: space-between;
  left: 0;
  position: fixed;
  top: 0;
  width: var(--width-sidebar);
  z-index: var(--z-index-sidebar);

  .actionItem {
    align-items: center;
    border: none;
    color: var(--gray-400);
    cursor: pointer;
    display: flex;
    flex-flow: column nowrap;
    padding: 0.5rem 0 0;
    position: relative;
    transition: filter 70ms ease-in-out;
    width: 100%;

    &[active],
    &.actionItemActive,
    &:hover {
      color: white;
    }

    :global(svg) {
      height: auto;
      width: var(--width-sidebar-nav-icon);
    }
    .actionItemLabel {
      @include text-sidebar-label;
      padding-top: 0.375rem;
      padding-bottom: 2rem;
    }
  }

  .logo {
    align-items: flex-start;
    color: var(--gray-400) !important;
    display: flex;
    justify-content: center;
    margin: 3.5rem 0.25rem 1.75rem;
    padding: 0.5rem 0.25rem;

    &.logoActive,
    &:hover {
      color: white !important;
    }
  }

  .groupBottom {
    align-items: center;
    display: flex;
    flex-flow: column nowrap;
    padding: 1.5rem 0;
    padding: 1.5rem 0 calc(1.5rem + env(safe-area-inset-bottom)); // iOS notch fix

    .bottomActionItem {
      @include text-body-small;
      border-radius: var(--rounding-sidebar-button);
      height: var(--width-sidebar-bottom-icon);
      margin-bottom: 0.5rem;
      padding: 0;
      transition-property: background-color, color;
      transition-timing-function: ease-in;
      transition-duration: var(--transition-duration-popover);
      width: var(--width-sidebar-bottom-icon);

      &.iconButton {
        padding: 0.4375rem;
        :global(svg) {
          height: auto;
          width: 100%;
        }
      }

      &.helpCenterButton {
        background-color: var(--gray-100);
        color: var(--gray-900) !important;

        &:hover {
          background-color: var(--gray-900);
          color: var(--gray-100) !important;
        }
      }
      &.intercomButton {
        background-color: var(--secondary-400);

        &:hover {
          background-color: var(--secondary-800);
          color: var(--secondary-400);
        }
      }
      &.menuButton {
        background-color: var(--primary-200);
        color: var(--gray-900);

        &:hover {
          background-color: var(--gray-900);
          color: var(--primary-200);
        }
      }
    }
  }
}
