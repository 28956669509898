// Base font mixins. Apply font-weight: bold as needed.
@mixin font-apk-protocol {
  font-family: 'APK Protocol', sans-serif;
  font-style: normal;
  font-weight: normal;
}
@mixin font-neue-montreal {
  font-family: 'Neue Montreal', sans-serif;
  font-style: normal;
  font-weight: normal;
}

// Headings (normal)
@mixin text-heading-1 {
  @include font-apk-protocol;
  color: var(--gray-800);
  font-size: 4rem;
  letter-spacing: -0.02em;
  line-height: 1.375;
}
@mixin text-heading-2 {
  @include font-apk-protocol;
  color: var(--gray-800);
  font-size: 3.5rem;
  letter-spacing: -0.02em;
  line-height: 1.286;
}
@mixin text-heading-3 {
  @include font-apk-protocol;
  color: var(--gray-800);
  font-size: 3rem;
  letter-spacing: -0.02em;
  line-height: 1.167;
}
@mixin text-heading-4 {
  @include font-apk-protocol;
  color: var(--gray-800);
  font-size: 2.25rem;
  letter-spacing: -0.02em;
  line-height: 1.333;
}
@mixin text-heading-5 {
  @include font-apk-protocol;
  color: var(--gray-800);
  font-size: 1.5rem;
  letter-spacing: -0.02em;
  line-height: 1.333;
}

// Headings (bold)
@mixin text-heading-1-bold {
  @include text-heading-1;
  font-weight: bold;
}
@mixin text-heading-2-bold {
  @include text-heading-2;
  font-weight: bold;
}
@mixin text-heading-3-bold {
  @include text-heading-3;
  font-weight: bold;
}
@mixin text-heading-4-bold {
  @include text-heading-4;
  font-weight: bold;
}
@mixin text-heading-5-bold {
  @include text-heading-5;
  font-weight: bold;
}

// Body text (normal) and details
@mixin text-body-large {
  @include font-neue-montreal;
  color: var(--gray-800);
  font-size: 1.5rem;
  letter-spacing: 0.75px;
  line-height: 1.333;
}
@mixin text-body-medium {
  @include font-neue-montreal;
  color: var(--gray-800);
  font-size: 1.125rem;
  letter-spacing: 0.1px;
  line-height: 1.889;
}
@mixin text-body-small {
  @include font-neue-montreal;
  color: var(--gray-800);
  font-size: 1rem;
  letter-spacing: 0.1px;
  line-height: 1.375;
}
@mixin text-body-detail {
  @include font-neue-montreal;
  color: var(--gray-800);
  font-size: 0.875rem;
  letter-spacing: 0.1px;
  line-height: 1.286;
}

// Body text (bold)
@mixin text-body-large-bold {
  @include text-body-large;
  font-weight: bold;
}
@mixin text-body-medium-bold {
  @include text-body-medium;
  font-weight: bold;
}
@mixin text-body-small-bold {
  @include text-body-small;
  font-weight: bold;
}

// Custom mixins for non-standard typography in the designs
@mixin text-chip {
  @include font-neue-montreal;
  font-size: 0.625rem;
  letter-spacing: 0.1px;
  line-height: 1;
}
@mixin text-sidebar-label {
  @include font-neue-montreal;
  font-size: 0.6875rem;
  line-height: 1.1;
}
@mixin text-table-heading {
  @include font-neue-montreal;
  color: var(--gray-800);
  font-size: 0.688rem;
  letter-spacing: 0.01em;
  text-transform: uppercase;
}

// Media queries. See here for a rough reference: https://devfacts.com/media-queries-breakpoints-2022/.
@mixin media($width) {
  @media screen and (min-width: $width) {
    @content;
  }
}

@mixin phone {
  @include media(320px) {
    @content;
  }
}
@mixin phone-landscape {
  @include media(568px) {
    @content;
  }
}
@mixin tablet {
  @include media(768px) {
    @content;
  }
}
@mixin tablet-landscape {
  @include media(1024px) {
    @content;
  }
}
@mixin laptop {
  @include media(1366px) {
    @content;
  }
}
@mixin desktop {
  @include media(1680px) {
    @content;
  }
}

// Other utilities
@mixin max-lines($max-lines) {
  @supports (-webkit-line-clamp: $max-lines) {
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    -webkit-line-clamp: $max-lines;
    -webkit-box-orient: vertical;
  }
}
