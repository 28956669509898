@import '../../styles/mixins';

.external {
  &:global(.chakra-form-control) {
    :global(.chakra-input),
    :global(.chakra-select) {
      border-radius: 0 !important;
      border-left-width: 0 !important;
      border-right-width: 0 !important;
      border-top-width: 0 !important;
      font-size: 1.125rem !important;
      line-height: 1.75 !important;
      padding-left: 0;
      padding-right: 0;

      &[aria-invalid],
      &[data-invalid] {
        border-color: var(--gray-600) !important;
        box-shadow: none !important;
      }
      &:focus,
      &:focus-within {
        border-bottom: 1px solid var(--primary-400) !important;
        box-shadow: none !important;
        outline-width: 0 !important;
      }
    }

    :global(.chakra-form__error-message) {
      @include text-body-detail;
      --form-error-color: var(--error-500) !important;
      color: var(--error-500) !important;
    }
  }
}
