@import '../../../styles/mixins';

.modalHeader {
  align-items: center;
  display: flex;

  .iconContainer {
    align-items: center;
    background-color: var(--primary-100);
    border-radius: 100%;
    color: var(--gray-900);
    display: flex;
    height: 2rem;
    justify-content: center;
    margin-right: 0.75rem;
    width: 2rem;

    :global(svg) {
      height: 1.25rem;
      width: 1.25rem;
    }
  }
}

.formHeader {
  display: grid;
  gap: 1rem;

  @include phone-landscape {
    grid: auto-flow / repeat(2, 1fr);
  }

  :global(img) {
    border-radius: var(--rounding-card);
  }

  .snapshotActions {
    display: flex;
    flex-flow: column nowrap;

    :global(a) {
      text-align: center;
    }
  }
}

.formControl {
  padding-top: 1rem;
}
