@import '../../styles/mixins';

.viewerContainer {
  height: 100%;
  width: 100%;

  .canvas {
    position: absolute;
    touch-action: none;
    transition: opacity 150ms ease-out;
    user-select: none;

    &.hidden {
      display: none;
    }
  }
  .cursorCrosshair {
    cursor: crosshair;
  }
  .cursorPointer {
    cursor: pointer;
  }
}

.loadingIndicator {
  position: absolute;
}

.controls {
  background-color: white;
  border-radius: var(--rounding-card);
  bottom: var(--gutter-360-control-vertical);
  padding: 1rem;
  position: absolute;
  right: calc(2 * var(--gutter-360-control-horizontal) + 2.5rem);
}
