@import '../../styles/mixins';

.container {
  background-color: white;
  border-radius: var(--rounding-card);
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.04)) drop-shadow(4px 2px 8px rgba(0, 0, 0, 0.06));
  max-width: 22rem;
}

.header {
  border-bottom: 1px solid var(--gray-100);
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  padding: 1rem 1rem 0.25rem;

  .title {
    @include text-body-medium-bold;
    align-items: center;
    display: flex;

    :global(svg) {
      height: 1.25rem;
      width: 1.25rem;
      margin-right: 0.625rem;
    }

    &.titleSuccess {
      color: var(--secondary-700);
    }
    &.titleWarning {
      color: var(--error-200);
    }
    &.titleError {
      color: var(--error-500);
    }
  }
}

.body {
  @include text-body-detail;
  color: var(--gray-600);
  padding: 0.25rem 1rem 1rem;
}
