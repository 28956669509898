.accountAccordionItem {
  .formControl {
    margin-bottom: 2rem;

    .formLabel {
      font-size: 0.875rem;
      margin-bottom: 0.5rem;
      color: var(--gray-600);
    }
  }
}
