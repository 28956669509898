.floorPlanMap {
  background-color: var(--gray-100);
  height: 100vh; // Fallback for reasonably old browsers, iOS Safari < 11.1
  height: calc(100vh - env(safe-area-inset-bottom)); // Height minus bottom notch, iOS Safari < 15.4
  height: 100dvh; // Height minus bottom notch and navbar (see "dynamic viewport units")

  &.overNode {
    cursor: pointer;
  }

  :global(canvas) {
    height: 100%;
    position: absolute;
    width: 100%;
  }
  .floorplanImageContainer {
    background-color: var(--gray-100);
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    overflow: hidden;
    position: absolute;

    :global(img) {
      max-width: unset;
      mix-blend-mode: multiply;
      overflow: hidden;
      position: absolute;
    }
  }

  .overlayControlGroup {
    align-items: flex-end;
    bottom: var(--gutter-360-control-vertical);
    display: flex;
    position: fixed;
    right: var(--gutter-360-control-horizontal);

    .pathToggleGroup {
      margin-right: 0.5rem;
    }

    .pathToggleFormControl {
      align-items: center;
      display: flex;
      min-width: 6rem;
      padding: 0.25rem 0.375rem;

      :global(.chakra-form__label) {
        height: 2rem;
        margin: 0 0.375rem;
        padding-top: 0.25rem;
      }
    }
  }
}

.maskContainer {
  transition: background-color 0.4s ease-in-out 0.1s;
  &.active {
    background-color: rgba(255, 255, 255, 0.3);
    height: 100vh; // Fallback for reasonably old browsers, iOS Safari < 11.1
    height: calc(100vh - env(safe-area-inset-bottom)); // Height minus bottom notch, iOS Safari < 15.4
    height: 100dvh; // Height minus bottom notch and navbar (see "dynamic viewport units")
    position: absolute;
    top: 0;
    width: calc(100vw - var(--width-sidebar));
    z-index: var(--z-index-view360-controls);
  }
}

.miniMap {
  border-radius: var(--rounding-view-360-button);
  bottom: var(--gutter-360-control-vertical);
  height: 9.375rem;
  left: var(--gutter-360-control-horizontal);
  overflow: hidden;
  position: absolute;
  transition: height 0.4s ease-in-out 0.1s, width 0.4s ease-in-out 0.1s;
  width: 20rem;
  z-index: var(--z-index-view360-controls);

  &.overNode {
    cursor: pointer;
  }

  &.miniMapExpanded {
    bottom: var(--gutter-360-control-vertical);
    height: calc(100% - var(--gutter-360-control-vertical) * 2);
    left: var(--gutter-360-control-horizontal);
    width: calc(100% - var(--gutter-360-control-horizontal) * 2);
    z-index: var(--z-index-view360-expanded-minimap);

    :global(canvas),
    .floorplanImageContainer {
      bottom: 0;
      left: 0;
      overflow: hidden;
      position: absolute;
      right: 0;
      top: 0;
    }

    .overlayControlGroup {
      bottom: var(--gutter-360-control-vertical);
      position: absolute;
      right: var(--gutter-360-control-horizontal);
    }
  }
  &:not(.miniMapExpanded) {
    &:hover,
    &.panning {
      height: 21.875rem;

      :global(canvas),
      .floorplanImageContainer {
        margin-top: 0;
      }
    }

    :global(canvas),
    .floorplanImageContainer {
      margin-top: -6.25rem;
      position: absolute;
      transition: margin-top 0.4s ease-in-out 0.1s;
    }
  }

  .floorplanImageContainer {
    background-color: var(--gray-100);
    bottom: 0;
    left: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;

    :global(img) {
      max-width: unset;
      mix-blend-mode: multiply;
      overflow: hidden;
      position: absolute;
    }
  }
}
