@import '../../styles/mixins';

.baseLayout {
  position: relative;
}

.externalLayout {
  --gutter-card-top: 8.375rem;
  --max-card-content-width: 105rem;
  --max-watermark-width: 30rem;
  background: url('../../../public/resources/bg-grid.svg') repeat center;

  .logo {
    left: var(--gutter-full-screen-page-horizontal);
    position: absolute;
    top: 3rem;
  }
  .card {
    background-color: white;
    // To compute the image width, subtract the width of the horizontal gutters and the gap between grid rows.
    border-top-left-radius: var(--rounding-card-large);
    border-top-right-radius: var(--rounding-card-large);
    height: auto;
    left: 0;
    min-height: calc(100vh - var(--gutter-card-top));
    padding: var(--gutter-full-screen-page-vertical) var(--gutter-full-screen-page-horizontal) 0 !important;
    position: relative;
    top: var(--gutter-card-top);
    width: 100%;
  }
  .grid {
    display: grid !important;
    gap: 4rem;
    grid: auto-flow min-content / 1fr;
    // alternatively, using explicit rows (i.e. grid-template-rows), could have been:
    // grid: min-content min-content / 1fr;
    margin: 0 auto;
    max-width: var(--max-card-content-width);
    // In order for the watermark to be bottom-right positioned, the grid needs to be 100% height minus the top card
    // positioning minus the card padding.
    min-height: calc(100vh - var(--gutter-card-top) - var(--gutter-full-screen-page-vertical));
    padding: 0 0 var(--gutter-full-screen-page-vertical);
    position: relative;

    @include tablet-landscape {
      grid-template-columns: repeat(2, 1fr);
    }

    .watermark {
      bottom: 0;
      height: auto;
      max-width: var(--max-watermark-width);
      pointer-events: none;
      position: absolute;
      right: 0;
      width: min(calc((100% - 2 * var(--gutter-full-screen-page-horizontal) - 4rem) / 2), var(--max-watermark-width));
    }
  }
}

.constrainToPageHeight {
  height: 100vh; // Fallback for reasonably old browsers, iOS Safari < 11.1
  height: calc(100vh - env(safe-area-inset-bottom)); // Height minus bottom notch, iOS Safari < 15.4
  height: 100dvh; // Height minus bottom notch and navbar (see "dynamic viewport units")
}
