@import '../../styles/mixins';

.dataTableContainer {
  overflow-y: auto !important;

  .dataTable {
    // Without setting the z-index, the body content scrolls through the sticky header
    thead {
      z-index: 30;
    }

    .header {
      &.sortableHeader {
        &:hover {
          cursor: pointer;
          background-color: rgba(var(--gray-200-rgb), 0.3) !important;
        }
      }
    }
  }
}
