// Common utility classes -- import from any component file.
@import './styles/mixins';

// NOTE: this file being removed. Do not use these directly anymore.

// TODO: Remove this once the `ErrorIcon` can be used as a Chakra icon, e.g. <Icon as={ErrorIcon} width="3.5rem" />
.errorIcon {
  height: 3.5rem;
  width: 3.5rem;
  margin-bottom: 0.625rem;
}
