@import '../../styles/mixins';

.card {
  background-color: white;
  border-radius: var(--rounding-card);
  max-width: 37.5rem;
  padding: 1rem;

  :global(h1) {
    @include text-heading-5-bold;
    display: block;
    margin-bottom: 1rem;
  }
  :global(p) {
    @include text-body-small;
  }
  :global(a) {
    color: var(--primary-400);
  }
}
