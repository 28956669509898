@import '../styles/mixins';

.gridBackground {
  background: url('../../public/resources/bg-grid.svg') repeat center;
  width: 100vw;
  height: 100vh;
}

.content {
  padding: 5rem 3rem;

  :global(h1) {
    @include text-heading-2;
    padding-bottom: 1.5rem;
  }
  :global(p) {
    @include text-body-large;
  }
}

.action {
  @include text-heading-5;
  align-items: center;
  color: var(--gray-800) !important;
  display: flex;
  padding: 1rem 3rem;

  &:hover {
    color: var(--primary-600) !important;
  }
}

.splash {
  @include font-apk-protocol;
  color: var(--gray-400);
  font-size: 12.5rem;
  font-weight: bold;
  line-height: 1;
  opacity: 0.5;
  position: absolute;
  bottom: 0;
  right: 1rem;
  user-select: none;
  overflow: hidden;
}

@include phone-landscape {
  .content {
    padding-top: 2.5rem;
  }
}

@include tablet {
  .content {
    padding: 5rem;
    position: absolute;

    :global(h1) {
      @include text-heading-1;
    }
  }

  .action {
    bottom: 0;
    padding: 3rem 5rem;
    position: absolute;
  }

  .splash {
    font-size: 15rem;
    right: 3rem;
  }
}

@include tablet-landscape {
  .content {
    top: 5%;
    max-width: 65%;
  }
  .splash {
    font-size: 20rem;
  }
}

@include laptop {
  .content {
    top: 10%;
  }
  .splash {
    font-size: 25rem;
  }
}

@include desktop {
  .content {
    top: 20%;
  }
  .splash {
    font-size: 30rem;
  }
}
