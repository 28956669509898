@import '../styles/mixins';

.form {
  :global(h1) {
    @include text-heading-3;
    font-size: 2.5rem;
    letter-spacing: -0.01em;
    line-height: 1.2;
    padding-bottom: 1.5rem;
  }
  :global(h2) {
    @include text-heading-5;
    padding: 1rem 0 1.25rem;
  }
  :global(p) {
    @include text-body-medium;
    line-height: 1.55;
    padding: 0 0 1rem;
  }
  :global(.chakra-button) {
    height: auto;
    margin-top: 2rem;
  }
  :global(.chakra-form-control) {
    padding: 0 0 1.5rem;
  }
}

.passwordHelpText {
  .requirement {
    align-items: center;
    display: flex;
    gap: 0.375rem;
    padding: 0.25rem 0;

    &.requirementIndeterminate {
      :global(svg) {
        color: var(--gray-400);
      }
    }
    &.requirementFailed {
      color: var(--error-500);
    }
    &.requirementPassed {
      color: var(--secondary-700);
    }

    :global(svg) {
      height: 0.75rem;
      flex: 0 0 auto;
      height: 0.75rem;
    }
  }
}

.errorMessage {
  color: var(--error-500) !important;
}
