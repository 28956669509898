@import '../../styles/mixins';

.passwordHelpText {
  .requirement {
    align-items: center;
    display: flex;
    gap: 0.375rem;
    padding: 0.25rem 0;

    &.requirementIndeterminate {
      :global(svg) {
        color: var(--gray-400);
      }
    }
    &.requirementFailed {
      // Note: this is intentionally lighter than the same messages in other contexts. Since form controls use this
      // shade of orange for errors on internal parts of the site, this should stay orange unless only red is used
      // across the board.
      color: var(--error-200);
    }
    &.requirementPassed {
      color: var(--secondary-700);
    }

    :global(svg) {
      height: 0.75rem;
      flex: 0 0 auto;
      height: 0.75rem;
    }
  }
}

.successMessage {
  @include text-body-medium;
  align-items: center;
  color: var(--secondary-700);
  display: flex;
  padding-bottom: 1rem;

  :global(svg) {
    height: 1rem;
    margin-right: 0.375rem;
    width: 1rem;
  }
}
