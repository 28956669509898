@import '../../styles/mixins';

.popoverContent {
  border-radius: var(--rounding-card);
  width: 15rem !important;
}

.popoverBody {
  &:global(.chakra-popover__body) {
    align-items: flex-start;
    background-color: white;
    border-radius: var(--rounding-card);
    display: flex;
    flex-direction: column;
    padding: 0;

    a {
      @include text-body-medium;
      border-bottom: 0.0625rem solid var(--gray-100);
      color: var(--gray-600) !important;
      padding: 0.75rem 1rem;
      width: 100%;

      &:hover {
        color: var(--gray-900) !important;
      }
      &:last-of-type {
        border-bottom: none;
      }
    }
  }
}
