@import '../../styles/mixins';

.overlay {
  background-color: var(--gray-800);
  border-radius: var(--rounding-card);
  display: flex;
  flex-flow: column nowrap;
  opacity: 1;
  padding: 1rem;
  position: fixed;
  right: 1.5rem;
  top: 1.5rem;
  transition: opacity var(--transition-duration-coach) ease-in-out 300ms;
  visibility: visible;
  z-index: var(--z-index-view360-controls);

  &.overlayExit {
    opacity: 0;
    visibility: hidden;
  }
}

.title {
  @include text-body-medium-bold;
  color: white;
  margin-bottom: 0.5rem;
}

.body {
  @include text-body-detail;
  color: var(--gray-100);

  :global(p) {
    display: block;
    margin-bottom: 0.5rem;
  }
}
