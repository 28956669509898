.inviteUserStepOne {
  .formControl {
    margin-bottom: 2rem;

    .formLabel {
      font-size: 0.875rem;
      margin-bottom: 0;
      color: var(--gray-600);
    }

    .numProjects {
      font-size: 0.875rem;
      color: var(--gray-700);

      &.disabled {
        opacity: 0.4;
      }
    }
  }

  .accessAllProjects {
    margin-bottom: 2rem;
  }
}
