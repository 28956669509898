.loadingIndicator {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;

  &.fullPage {
    height: 100vh; // Fallback for reasonably old browsers, iOS Safari < 11.1
    height: calc(100vh - env(safe-area-inset-bottom)); // Height minus bottom notch, iOS Safari < 15.4
    height: 100dvh; // Height minus bottom notch and navbar (see "dynamic viewport units")
  }
}

.spinner {
  height: 6rem;
  width: 6rem;

  &.hidden {
    visibility: hidden;
  }
}
