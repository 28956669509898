@import '../../styles/mixins';

.chip {
  @include text-body-detail;
  background-color: var(--gray-100);
  border-radius: var(--rounding-chip-large);
  line-height: 1.125;
  margin-right: 0.5rem;
  padding: 0.25rem 0.75rem;
  width: fit-content;

  &.chipPrimary {
    background-color: var(--primary-100);
    color: var(--primary-800);
  }
  &.chipSecondary {
    background-color: var(--secondary-100);
    color: var(--secondary-800);
  }
}
