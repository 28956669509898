@import '../../styles/mixins';

.projectNameRowContainer {
  @include phone-landscape {
    gap: 1rem !important;
  }

  @include tablet {
    gap: 2rem !important;
  }

  .projectImage {
    display: none;

    @include phone-landscape {
      border-radius: var(--rounding-card);
      display: block;
      height: 3rem;
      width: 3rem;
      min-width: 3rem;
    }

    @include tablet {
      height: 3.5rem;
      width: 3.5rem;
      min-width: 3.5rem;
    }
  }

  .projectName {
    @include text-body-detail;
    @include max-lines(2);
    color: var(--gray-800) !important;
    font-weight: bold;
    white-space: normal;

    &:hover {
      color: var(--primary-600) !important;
    }

    @include tablet {
      @include text-body-small-bold;
    }

    @include desktop {
      @include text-body-medium-bold;
    }
  }

  .projectAddress {
    @include text-body-detail;
    white-space: pre-wrap;
    color: var(--gray-600);
  }
}
